<template>
  <CCard >
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-layers"/> {{caption}}
        <div class="card-header-actions">
          <div role="group" class="form-group">
            <CButton color="warning" size="sm"  @click="initTags()">Etiquetas de Registro</CButton>
            <CButton color="success" size="sm"  @click="downloadExcel()">Exportar Excel</CButton>
            <CButton color="dark" size="sm"  @click="downloadTags()">Crear Etiquetas</CButton>
            <CButton color="dark" size="sm"  @click="downloadTagsMini()">Crear Etiquetas Mini</CButton>
            <CButton color="info" size="sm" @click="storeModal()">Nuevo Activo</CButton>
          </div>          
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CRow>        
        <CCol md="12">
          <!-- FILTROS -->
          <CRow>
              <CCol md="2">
                <div role="group" class="form-group" style="margin: -10px 0px 10px">
                  <label> Categoria:</label>
                  <CSelect
                    :value.sync="categoriesSelect" 
                    :options="categoriesOptions"
                    @change="changeFilters"
                  />                        
                </div>
              </CCol>
              <CCol md="3">
                <div role="group" class="form-group" style="margin: -10px 0px 10px">
                  <label> Lugar:</label>
                  <CSelect
                    :value.sync="locationSelect" 
                    :options="locationsOptions"
                    @change="changeFilters"
                  />                        
                </div>
              </CCol>       
              <CCol md="3">
                <div role="group" class="form-group" style="margin: -10px 0px 10px">
                  <label> Departamento:</label>
                  <CSelect
                    :value.sync="departamentSelect" 
                    :options="departamentsOptions"
                    @change="changeFilters"
                  />                        
                </div>
              </CCol>              
              <CCol md="2">
                <div role="group" class="form-group" style="margin: -10px 0px 10px">
                  <label> Persona:</label>
                  <CSelect
                    :value.sync="personSelect" 
                    :options="personsOptions"
                    @change="changeFilters"
                  />                        
                </div>
              </CCol>
              <CCol md="2">
                <div role="group" class="form-group" style="margin: -10px 0px 10px">
                  <label> Condición:</label>
                  <CSelect
                    :value.sync="conditionsSelect" 
                    :options="conditionsOptions"
                    @change="changeFilters"
                  />                        
                </div>
              </CCol>                    
          </CRow>     
          <!-- PESTAÑAS -->
          <!--
          <ul class="nav nav-tabs justify-content-begin">
            <li @click="changeTab('active')" class="nav-item">
              <a class="nav-link" :class="tab == 'active' ? 'active' : ''" aria-current="page">Activos</a>
            </li>
            <li @click="changeTab('inactive')" class="nav-item">
              <a class="nav-link" :class="tab == 'inactive' ? 'active' : ''">Inactivos</a>
            </li>
          </ul>
          -->
        </CCol>
      </CRow>
      <br>
      <CRow>
        <CCol md="12" style="font-size: 14px;">
          <CDataTable
            :hover="hover"
            :striped="striped"
            :border="border"
            :small="small"
            :fixed="fixed"
            :items="items"
            :fields="fields"
            :items-per-page="small ? 30 : 50"
            :dark="dark"
            pagination
          >
            <template #name="{item}"><td class="text-left" style="font-size: 12px;"><b style="font-size: 14px;">{{item.name}}</b><br /><span style="font-size: 12px;">{{item.description}}</span><span><span v-if="item.serie"><br /><b>Serie: </b><span style="font-size: 12px;">{{item.serie}}</span></span></span></td></template>
            <template #adquisition_value="{item}"><td class="text-right">${{item.adquisition_value | currency}}</td></template>
            <template #adquisition_date="{item}"><td class="text-center">{{item.adquisition_date | date}}</td></template>
            <template #code="{item}"><td class="text-center">{{item.code}}</td></template>
            <template #category="{item}"><td class="text-center">{{item.category}}</td></template>
            <template #location="{item}"><td class="text-center">{{item.location}}</td></template>
            <template #departament="{item}"><td class="text-center">{{item.departament}}</td></template>
            <template #condition="{item}"><td class="text-center">{{item.condition}}</td></template>
            <template #actions="{item}">
              <td style="padding: 0px; padding-bottom: 2px;" class="table_actions w150 text-center">
                <CButton v-if="item.is_active" color="warning" size="sm" @click="updateModal(item)"><CIcon name="cil-pencil"/></CButton>                
                  <template v-if="item.is_active">
                    <CButton color="danger" size="sm" @click="deleteModal(item)"><CIcon name="cil-trash"/></CButton>
                  </template>
                  <template v-else>
                    <CButton color="success" size="sm" @click="deleteModal(item)"><CIcon name="cil-check-circle"/></CButton>
                  </template>
                <table style="padding: 0px; margin: auto; ">
                   <tr>
                    <td style="padding: 0px; border: 0px !important;">
                      <CButton color="info" size="sm" @click="downloadRecord(item)">Expediente</CButton>
                    </td>
                    <td style="padding: 0px; border: 0px !important;">
                      <CDropdown color="info" togglerText="" size="sm">
                        <CDropdownItem href="#" v-if="item.is_active" @click="assignModal(item)">Asignar Persona</CDropdownItem>
                        <CDropdownItem href="#" v-if="item.is_active && item.person != ''" @click="unassignModal(item)">Desasignar Persona</CDropdownItem>                        
                        <CDropdownDivider/>         
                        <CDropdownItem href="#" v-if="item.is_active" @click="documentModal(item)">Documentación</CDropdownItem>                        
                        <template v-if="item.documents.length > 0">
                          <CDropdownDivider/>
                          <CDropdownItem target="_blank" v-for="document in item.documents" :key="'document-a'+document.id" :href="document.file_url">{{document.name}}</CDropdownItem>
                        </template>                        
                      </CDropdown>
                    </td>
                    </tr>
                </table>
              </td>
            </template>
          </CDataTable>
        </CCol>
      </CRow>
    </CCardBody>
    <AssetModal ref="addModal" @store="storeProcess" @update="updateProcess"></AssetModal>
    <AssetAssignModal ref="assignModal" @store="storeProcessAssign" @update="updateProcessAssign"></AssetAssignModal>
    <AssetDocumentModal ref="documentModal" @store="storeProcessDocument" @delete="deleteProcessDocument"></AssetDocumentModal>
    <DeleteModal ref="deleteModal" @confirm="deleteProcess"></DeleteModal>
  </CCard>
</template>

<script>
import AssetModal from './AssetModal.vue';
import AssetAssignModal from './AssetAssignModal.vue';
import AssetDocumentModal from './AssetDocumentModal.vue';
import DeleteModal from '../global/ConfirmModal.vue';
import ws_cat from '../../services/asset_categories';
import ws_con from '../../services/asset_conditions';
import ws_dep from '../../services/asset_departaments';
import ws_loc from '../../services/asset_locations';
import ws_per from '../../services/asset_persons';
import ws_assig from '../../services/asset_allocations';
import ws_doc from '../../services/asset_documents';
import ws from '../../services/assets';
import store from '../../store'
import moment from 'moment';
import moment_timezone from 'moment-timezone';

export default {
  name: 'AssetGeneralTable',
  components: { AssetModal, DeleteModal,AssetAssignModal,AssetDocumentModal },
  props: {
    items: Array,
    permissions: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'code', label: 'Codigo'},
          {key: 'name', label: 'Nombre'},
          // {key: 'serie', label: 'Serie'},
          {key: 'category', label: 'Categoria'},
          {key: 'location', label: 'Lugar'},
          {key: 'departament', label: 'Departamento'},
          {key: 'condition', label: 'Condición'},
          // {key: 'person', label: 'Persona'},
          // {key: 'adquisition_date', label: 'Fecha'},
          // {key: 'adquisition_value', label: 'Valor'},
          {key: 'actions', label: 'Acciones', class: 'actions' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: [],
      idToDelete: "",
      moduleDelete: "",
      tab: 'active',
      categoriesSelect: "",
      departamentSelect: "",
      conditionsSelect: "",
      personSelect: "",
      locationSelect: "",
      categoriesOptions: [],
      departamentsOptions: [],
      conditionsOptions: [],
      personsOptions: [],
      locationsOptions: [],
      totals: {
        active: -1,
        inactive: -1,
      },
    }
  },
  mounted: async function() {
    this.loading();

    let response_cat = await ws_cat.get();
    if(response_cat.type == "success"){
      this.categoriesOptions = await this.$parseSelectOptionsOrdered(response_cat.data, "id", "name",[{"label": "Todos","value":""}]);
    }
    let response_dep = await ws_dep.get();
    if(response_dep.type == "success"){
      this.departamentsOptions = await this.$parseSelectOptionsOrdered(response_dep.data, "id", "name",[{"label": "Todos","value":""}]);
    }
    let response_con = await ws_con.get();
    if(response_con.type == "success"){
      this.conditionsOptions = await this.$parseSelectOptionsOrdered(response_con.data, "id", "name",[{"label": "Todos","value":""}]);
    }
    let response_loc = await ws_loc.get();
    if(response_loc.type == "success"){
      this.locationsOptions = await this.$parseSelectOptionsOrdered(response_loc.data, "id", "name",[{"label": "Todos","value":""}]);
    }
    let response_per = await ws_per.get();
    if(response_per.type == "success"){
      this.personsOptions = await this.$parseSelectOptionsOrdered(response_per.data, "id", "name",[{"label": "Todos","value":""}]);
    }

    this.loaded();
  },
  methods: {
    changeTab (tab) {
      if(this.tab != tab){
        this.tab = tab;
        let filters = this.getFilters();
        this.$emit("refreshItems", filters);
      }
    },
    async downloadExcel(){
      this.loading();

      let filters = this.getFilters();
      await ws.downloadExcel(filters);
      
      this.loaded();
    },
    async changeFilters () {
      let filters = this.getFilters();
      this.$emit("refreshItems", filters);
    },
    getFilters(){
      let filters = {
        asset_category_id: this.categoriesSelect,
        asset_departament_id: this.departamentSelect,
        asset_conditions_id: this.conditionsSelect,
        asset_location_id: this.locationSelect,
        asset_person_id: this.personSelect,
        is_active: (this.tab == "active") ? 1 : 0 
      };

      return filters;
    },
    storeModal () {
      this.$refs.addModal.storeModal();
    },
    updateModal (item) {
      this.$refs.addModal.updateModal(item);
    },
    assignModal (item) {
      this.$refs.assignModal.storeModal(item);
    },
    unassignModal (item) {    
      this.idToDelete = item.id;
      this.moduleDelete = "unassign";
      let color = "warning";
      let title = "Desasignar Persona";
      let content = "Esta seguro que quiere desasignar a " +item.person+" del siguiente activo?"
      let confirm = item.code + " - " + item.name
      this.$refs.deleteModal.show(color,title,content,confirm);
    },  
    documentModal (item) {
      this.$refs.documentModal.storeModal(item);
    },
    deleteModal (item) {    
      this.idToDelete = item.id;
      this.moduleDelete = "delete";
      let color = "success";
      let title = "Reactivar Registro";
      let content = "¿Estás seguro que deseas reactivar el registro?"
      let confirm = "<span style='font-size: 16px;font-weight: bold;'>"+item.code + " - " + item.name+"</span><br /><span style='font-size: 14px;'>"+item.description+"</span>";
      if(this.tab == "active"){
        color = "danger";
        title = "Archivar Activo";
        content = "¿Estás seguro que deseas archivar el activo?"
      }
      this.$refs.deleteModal.show(color,title,content,confirm);
    },        
    async storeProcess(data) {
      this.loading();
      let response = await ws.store(data);      
      if(response.type == "success"){
        this.$emit("refreshItems",this.getFilters());
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async updateProcess(data) {
      this.loading();
      let response = await ws.update(data);

      if(response.type == "success"){
        this.$emit("refreshItems",this.getFilters());
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async deleteProcess() {
      this.loading();
      let response = "";
      if(this.moduleDelete == "delete"){
        response = await ws.delete({id: this.idToDelete});            
      }else{
        response = await ws_assig.unassign({id: this.idToDelete});
      }

      if(response.type == "success"){
        this.$emit("refreshItems",this.getFilters());
      }      

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async storeProcessAssign(data) {
      this.loading();
      let date = moment(data.date).format("YYYY-MM-DD");

      data.date = date;
      let response = await ws_assig.store(data);      
      if(response.type == "success"){
        this.$emit("refreshItems",this.getFilters());
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async updateProcessAssign(data) {
      this.loading();
      let response = await ws_assig.update(data);

      if(response.type == "success"){
        this.$emit("refreshItems",this.getFilters());
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async storeProcessDocument(data) {
      console.log("entro aqui");
      this.loading();
      let response = await ws_doc.store(data);      
      if(response.type == "success"){
        this.$emit("refreshItems",this.getFilters());
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async deleteProcessDocument(data) {
      this.loading();
        let response = await ws_doc.delete({id:data});
        if(response.type == "success"){
          this.$emit("refreshItems",this.getFilters());
        }
        this.showToast(response.type, response.message);
      this.loaded();
    },
    async downloadRecord(item){
      this.loading();

      await ws.downloadRecord({id: item.id});
      
      this.loaded();
    },
    async initTags(){
      this.loading();
      await ws.initTags(24);
      
      this.loaded();
    },
    async downloadTags(){
      this.loading();
      let filters = this.getFilters();
      await ws.downloadTags(filters);
      
      this.loaded();
    },
    async downloadTagsMini(){
      this.loading();
      let filters = this.getFilters();
      await ws.downloadTagsMini(filters);
      
      this.loaded();
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
}
</script>

<template>
  <div>
    <CModal
    :title="title"
    :color="color"
    :show.sync="showModal"
    >   
      <template v-if="item.documents.length > 0">
        <CRow>
          <CCol class="text-center" md="12">
            <h5>Documentos Existentes</h5>
          </CCol>
        </CRow>
        <CRow v-for="document in item.documents" :key="'exists_documents'+document.id">
          <CCol class="text-center" md="6">
            {{document.name}}
          </CCol>      
          <CCol class="text-center" md="6">
            <CButton style="margin-right: 5px;" color="danger" size="sm" @click="deleteDocument(document)"><CIcon name="cil-trash"/></CButton>
            <CButton target="_blank" color="dark" size="sm" :href="document.file_url"><CIcon name="cil-cloud-download"/></CButton>
          </CCol>      
        </CRow>
        <br>
        <br>
      </template>       
      <CForm id="document_form" method="POST" enctype="multipart/form-data">
        <CInput
          :lazy="false"
          :value.sync="$v.form.asset_id.$model"
          name="asset_id"
          id="asset_id"
          hidden
        />
        <CRow>
          <CCol class="text-center" md="12">
            <h5>Nueva Documentación</h5>
          </CCol>
        </CRow>
        <CRow>
          <CCol md="6">
            <CInput
              label="Nombre:"
              :lazy="false"
              :value.sync="$v.form.name_1.$model"
              placeholder=""
              name="name_1"
              id="name_1"
              autocomplete="given-name"
            />                              
          </CCol>      
          <CCol md="6">
            <CInput
              label="Documento:"
              :lazy="false"
              :value.sync="$v.form.document_1.$model"
              type="file"
              name="document_1"
              id="document_1"
              placeholder=""
              autocomplete="given-name"
            />
          </CCol>      
        </CRow>
        <CRow v-if="moreDocument >= 2">
          <CCol md="6">
            <CInput
              label="Nombre:"
              :lazy="false"
              :value.sync="$v.form.name_2.$model"
              placeholder=""
              name="name_2"
              id="name_2"
              autocomplete="given-name"
            />                              
              </CCol>      
              <CCol md="6">
                <CInput
                label="Documento:"
                :lazy="false"
                :value.sync="$v.form.document_2.$model"
                type="file"
                name="document_2"
                id="document_2"
                placeholder=""
                autocomplete="given-name"
              />
              </CCol>      
            </CRow>
            <CRow v-if="moreDocument >= 3">
              <CCol md="6">
                <CInput
                  label="Nombre:"
                  :lazy="false"
                  :value.sync="$v.form.name_3.$model"
                  placeholder=""
                  name="name_3"
                  id="name_3"
                  autocomplete="given-name"
                />                              
              </CCol>      
              <CCol md="6">
                <CInput
                label="Documento:"
                :lazy="false"
                :value.sync="$v.form.document_3.$model"
                type="file"
                name="document_3"
                id="document_3"
                placeholder=""
                autocomplete="given-name"
              />
              </CCol>      
            </CRow>
            <CRow v-if="moreDocument >= 4">
              <CCol md="6">
                <CInput
                  label="Nombre:"
                  :lazy="false"
                  :value.sync="$v.form.name_4.$model"
                  placeholder=""
                  name="name_4"
                  id="name_4"
                  autocomplete="given-name"
                />                              
              </CCol>      
              <CCol md="6">
                <CInput
                label="Documento:"
                :lazy="false"
                :value.sync="$v.form.document_4.$model"
                type="file"
                name="document_4"
                id="document_4"
                placeholder=""
                autocomplete="given-name"
              />
              </CCol>      
            </CRow>
            <CRow v-if="moreDocument >= 5">
              <CCol md="6">
                <CInput
                  label="Nombre:"
                  :lazy="false"
                  :value.sync="$v.form.name_5.$model"
                  placeholder=""
                  name="name_5"
                  id="name_5"
                  autocomplete="given-name"
                />                              
              </CCol>      
              <CCol md="6">
                <CInput
                label="Documento:"
                :lazy="false"
                :value.sync="$v.form.document_5.$model"
                type="file"
                name="document_5"
                id="document_5"
                placeholder=""
                autocomplete="given-name"
              />
              </CCol>      
            </CRow>
            <CRow v-show="showMoreDocument">
              <CCol md="10">                
              </CCol>      
              <CCol md="2">                
                <CButton color="info" size="sm" @click="newDocumentLine()"><CIcon name="cilPlus"/></CButton>
              </CCol>      
            </CRow>   
            <br>                    
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
    <DeleteModal id="delete-document" ref="deleteModal" @confirm="deleteProcess"></DeleteModal>
  </div>
    
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, minValue, helpers } from "vuelidate/lib/validators"
import ws_per from '../../services/asset_persons';
import moment from 'moment';
import { DatePicker } from 'v-calendar'
import moment_timezone from 'moment-timezone';
import DeleteModal from '../global/ConfirmModal.vue';


export default {
  name: 'AssetDocumentModal',
  props: {
  },
  components: {
    'v-date-picker': DatePicker,
    DeleteModal
  },
  data () {
    return {
      showModal: false,
      showMoreDocument: true,
      moreDocument: 1,
      title: "Documentación",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,      
      personsOptions: [],
      item: {
        documents: []
      },
      idToDelete: "",
    }
  },
  mounted: async function() {    
    let response_per = await ws_per.get();
    if(response_per.type == "success"){
      this.personsOptions = await this.$parseSelectOptionsOrdered(response_per.data, "id", "name",[]);
    }
  },
  methods: {
    storeModal (item) {
      this.showModal = true;
      this.color = "info";
      this.title = 'Documentación';
      this.isEdit = false;
      this.submitted = false;
      this.item = item;

      this.form = {
          id: 0,
          asset_id: item.id,
          name_1: "",
          document_1: "",
          name_2: "",
          document_2: "",
          name_3: "",
          document_3: "",
          name_4: "",
          document_4: "",
          name_5: "",
          document_5: "",
      }
      this.showMoreDocument = true;
      this.moreDocument = 1;
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        let form = document.getElementById("document_form");
        let formData = new FormData(form);

        this.$emit("store", formData);

        this.closeModal();
      }
    },
    deleteDocument (item) {
      this.idToDelete = item.id;
      let color = "danger";
      let title = "Eliminar Documento";
      let content = "Esta seguro que quiere el siguiente Documento?"
      let confirm = item.name
      this.$refs.deleteModal.show(color,title,content,confirm);
    },
    deleteProcess () {
        this.$emit("delete", this.idToDelete);
        this.closeModal();
    },
    newDocumentLine() {
      this.moreDocument ++; 
      if(this.moreDocument >= 5){
        this.showMoreDocument= false;
      }
    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          name_1: "",
          document_1: "",
          name_2: "",
          document_2: "",
          name_3: "",
          document_3: "",
          name_4: "",
          document_4: "",
          name_5: "",
          document_5: "",
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      asset_id: {
        required,
        minValue: minValue(1)
      },
      name_1: {        
      },
      document_1: {
      },
      name_2: {        
      },
      document_2: {
      },
      name_3: {        
      },
      document_3: {
      },
      name_4: {        
      },
      document_4: {
      },
      name_5: {        
      },
      document_5: {
      },
    }
  },
}
</script>
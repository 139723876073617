<template>
  <div>
    <CRow>
      <CCol sm="12">
        <AssetsCatalogTable
          :items="assets"
          hover
          striped
          border
          small
          fixed
          caption="Inventario de Activos"
          @refreshItems="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import AssetsCatalogTable from '../../components/assets/AssetsCatalogTable.vue'
import ws from '../../services/assets';
import store from '../../store'

export default {
  name: 'AssetsCatalog',
  components: { AssetsCatalogTable },
  data: function () {
		return {
            assets: []
        }
  },
  mounted: async function() {
    this.loading();

    this.category = await this.$route.params.category;

    let response = false;

    if(this.category === undefined){
      response = await ws.get();
    }
    else{
      response = await ws.getByCategory(this.category);
    }

    if(response.type == "success"){
      this.assets = response.data;
    }

    this.loaded();
  },
  methods: {
    async refreshItems (filters){
      this.loading();
        let response = await ws.get(filters);
        if(response.type == "success"){
          this.assets = response.data;
        }
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
